var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"fullscreen"},[_c('v-card-title',[_vm._v(" Affecter les tâches "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.close}},'v-icon',attrs,false),on),[_vm._v("mdi-close ")])]}}])},[_c('span',[_vm._v("Fermer")])])],1),_c('v-divider'),(_vm.tasks[_vm.taskIndex])?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"task-nav",attrs:{"cols":"12","lg":"2"}},[_c('v-list',{staticClass:"transparent sticky-top-0",attrs:{"dense":"","nav":""}},_vm._l((_vm.tasks),function(task,i){return _c('v-list-item',{key:i,class:i===_vm.taskIndex ? 'bg-blue-lighten' : '',attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('update:taskIndex',i)}}},[_c('v-list-item-avatar',{attrs:{"size":"20"}},[_c('v-icon',{attrs:{"dark":i===_vm.taskIndex,"size":"20"}},[_vm._v("mdi-clipboard-text-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(task.name)+" ")])],1)],1)}),1)],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-card',{staticClass:"shadow rounded-lg sticky-top-0"},[_c('v-card-title',[_vm._v(" Choisir une tâche ")]),_c('v-divider'),_c('v-card-text',[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.tasks[_vm.taskIndex].question_type_key),callback:function ($$v) {_vm.$set(_vm.tasks[_vm.taskIndex], "question_type_key", $$v)},expression:"tasks[taskIndex].question_type_key"}},[_vm._l((_vm.taskModels),function(item,i){return _c('v-list-item',{key:i,attrs:{"value":item.question_type.key,"color":"primary"},on:{"change":function($event){[
                                     _vm.tasks[_vm.taskIndex].question_type_key = item.key,
                                     _vm.tasks[_vm.taskIndex].task_model_id = item.id,
                                 ]}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}})],1),(_vm.tasks[_vm.taskIndex].question_type_key === item.question_type.key)?_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1)}),_c('v-list-item',{attrs:{"color":"primary","value":"quiz"},on:{"change":function($event){[
                        _vm.tasks[_vm.taskIndex].question_type_key === 'quiz',
                        _vm.tasks[_vm.taskIndex].task_model_id = '',
                    ]}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clipboard-file-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Quiz / Formation")])],1),(_vm.tasks[_vm.taskIndex].question_type_key === 'quiz')?_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1)],2)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[(_vm.tasks[_vm.taskIndex].question_type_key === 'image')?_c('div',[_c('Photo',{attrs:{"response":_vm.response,"task-index":_vm.taskIndex},on:{"update:response":function($event){_vm.response=$event},"update:taskIndex":function($event){_vm.taskIndex=$event},"update:task-index":function($event){_vm.taskIndex=$event}}})],1):_vm._e(),(_vm.tasks[_vm.taskIndex].question_type_key === 'condition')?_c('div',[_c('Conditions',{attrs:{"response":_vm.response,"task-index":_vm.taskIndex},on:{"update:response":function($event){_vm.response=$event},"update:taskIndex":function($event){_vm.taskIndex=$event},"update:task-index":function($event){_vm.taskIndex=$event}}})],1):_vm._e(),(_vm.tasks[_vm.taskIndex].question_type_key === 'quiz')?_c('div',[_c('Quiz',{attrs:{"task-index":_vm.taskIndex},on:{"update:taskIndex":function($event){_vm.taskIndex=$event},"update:task-index":function($event){_vm.taskIndex=$event}}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between mt-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"primary","depressed":""},on:{"click":_vm.close}},[_vm._v(" Valider "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1)],1)])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
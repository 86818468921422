<template>
  <div>
    <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
      <v-card class="fullscreen">
        <v-card-title>
          Affecter les tâches
          <v-spacer/>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs"
                      @click="close" v-on="on">mdi-close
              </v-icon>
            </template>
            <span>Fermer</span>
          </v-tooltip>
        </v-card-title>
        <v-divider/>
        <v-container fluid v-if="tasks[taskIndex]">
          <v-row>

            <v-col class="task-nav" cols="12" lg="2">
              <v-list class="transparent sticky-top-0" dense nav>
                <v-list-item
                        v-for="(task,i) in tasks"
                        :key="i" :class="i===taskIndex ? 'bg-blue-lighten' : ''"
                        color="primary"
                        @click="$emit('update:taskIndex',i)">
                  <v-list-item-avatar size="20">
                    <v-icon :dark="i===taskIndex " size="20">mdi-clipboard-text-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ task.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" lg="3">
              <v-card class="shadow rounded-lg sticky-top-0">
                <v-card-title>
                  Choisir une tâche
                </v-card-title>
                <v-divider/>
                <v-card-text>

                  <v-list nav>
                    <v-list-item-group v-model="tasks[taskIndex].question_type_key" mandatory>

                      <v-list-item v-for="(item, i) in taskModels"
                                   :key="i"
                                   :value="item.question_type.key"
                                   color="primary"
                                   @change="[
                                       tasks[taskIndex].question_type_key = item.key,
                                       tasks[taskIndex].task_model_id = item.id,
                                   ]">
                        <v-list-item-icon>
                          <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-html="item.name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="tasks[taskIndex].question_type_key === item.question_type.key">
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item color="primary"
                                   value="quiz"
                                   @change="[
                          tasks[taskIndex].question_type_key === 'quiz',
                          tasks[taskIndex].task_model_id = '',
                      ]">
                        <v-list-item-icon>
                          <v-icon>mdi-clipboard-file-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Quiz / Formation</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="tasks[taskIndex].question_type_key === 'quiz'">
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-action>
                      </v-list-item>

                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" lg="7">
              <div v-if="tasks[taskIndex].question_type_key === 'image'">
                <Photo :response.sync="response" :task-index.sync="taskIndex"/>
              </div>
              <div v-if="tasks[taskIndex].question_type_key === 'condition'">
                <Conditions :response.sync="response" :task-index.sync="taskIndex"/>
              </div>
              <div v-if="tasks[taskIndex].question_type_key === 'quiz'">
                <Quiz :task-index.sync="taskIndex"/>
              </div>
              <div class="d-flex justify-space-between mt-3">
                <v-spacer/>
                <v-btn class="rounded-lg"
                       color="primary"
                       depressed
                       @click="close">
                  Valider
                  <v-icon right>mdi-check</v-icon>
                </v-btn>
              </div>
            </v-col>

          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Photo from "../../fields/Photo";
import Conditions from "../../fields/Conditions";
import Quiz from "../../fields/Quiz";

export default {
    components: {Quiz, Conditions, Photo},
    props: ['dialog', 'taskIndex'],
    data() {
        return {
            infoDialog: false,
            infoText: '',
            response: 'La réponse de cette tâche est une image'
        }
    },
    methods: {
        close() {
            this.$emit('update:dialog', false)
        },
    },
    computed: {
        tasks() {
            return this.$store.state.campaign.tasks;
        },
        taskModels() {
            return this.$store.state.task_models;
        },
    }
}
</script>

<style scoped>
</style>
import { render, staticRenderFns } from "./QuestionsDialog.vue?vue&type=template&id=fbf3484a&scoped=true&"
import script from "./QuestionsDialog.vue?vue&type=script&lang=js&"
export * from "./QuestionsDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbf3484a",
  null
  
)

export default component.exports
<template>
  <div>
    <v-card class="shadow rounded-lg" :loading="question.upload_loading">
      <v-card-text class="pa-6">
        <v-row justify="center">
          <v-col cols="12" lg="12">

            <v-text-field label="Description *"
                          filled dense
                          hide-details
                          class="mb-2"
                          v-model.trim="question.description"></v-text-field>

            <v-text-field label="* الوصف"
                          class="ar rtl mb-2"
                          filled dense
                          hide-details
                          v-model.trim="question.description_ar"></v-text-field>

            <v-alert type="info" text>
              <strong>Remarque : </strong> {{ response }}
            </v-alert>
            <div @click="onButtonClick" class="text-center pa-6 cursor-pointer"
                 style="border-style: dashed;">
              <v-btn :loading="question.upload_loading"
                     text class="v-btn--active pa-10" color="primary" fab depressed>
                <v-icon color="primary">mdi-cloud-upload</v-icon>
              </v-btn>
              <strong class="mt-4 d-block"> Télécharger une image</strong>
              <input accept="image/png,image/jpeg" ref="uploader" class="d-none" type="file"
                     @change="uploadPhoto($event)">
            </div>
          </v-col>
        </v-row>
        <div v-if="question.content" class="mt-3 text-center">
          <v-avatar tile size="200"
                    @click="[image = [{src: FILE_URL + question.content}],visible = true]">
            <v-img :src="FILE_URL + question.content" class="rounded cursor-pointer"/>
          </v-avatar>
          <v-spacer/>
        </div>
        <v-alert type="error" text v-if="errors">
          <ul>
            <li v-for="(error,i) in errors" :key="i">{{ error[0] }}</li>
          </ul>
        </v-alert>
      </v-card-text>
    </v-card>
    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="image" :index="index"
                       @hide="visible = false"></vue-easy-lightbox>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  props: ['taskIndex', 'response'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      //Show image
      image: '',
      visible: false,
      index: 0,
      errors: ''
    }
  },
  methods: {
    uploadPhoto(file) {
      this.question.upload_loading = true
      this.errors = ''
      let formData = new FormData()
      formData.append('photo', file.target.files[0])
      HTTP.post('/campaigns/upload-image', formData).then((res) => {
        this.question.upload_loading = false
        this.question.content = res.data.file_name
      }).catch(err => {
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.question.upload_loading = false
        this.errors = err.response.data.errors
        console.log(err)
      })
    },
    onButtonClick() {
      window.addEventListener('focus', () => {
      }, {once: true})
      this.$refs.uploader.click()
    },
  },
  computed: {
    question() {
      return this.$store.state.campaign.tasks[this.taskIndex].questions[0]
    },
  }
}
</script>

<style>
</style>